<template>
  <div class="app-container">
    <div>
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item v-if="store.state.user.token == null" index="2">登录</el-menu-item>
        <el-sub-menu v-else index="3">
          <template #title>{{ store.state.user.name }}</template>
          <el-menu-item index="3-1">进入控制台</el-menu-item>
          <el-menu-item index="3-2">修改密码</el-menu-item>
        </el-sub-menu>

      </el-menu>
    </div>
    <div>
      <el-main>
        <router-link to="/">Home</router-link>
      </el-main>
    </div>

  </div>
  <div class="footer">
    <a href="https://beian.miit.gov.cn" target="_blank"><span>粤ICP备18087157号-1</span></a>
  </div>
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  setup(props,context) {
    //setup有两个参数props,context
    //props参数，是一个对象，里面有父级组件向子级组件传递是数据，并且是在子级组件中使用props接受到的所有的属性
    //context参数，是一个对象，里面有attrs对象（获取当前组件标签上所有属性的对象，但是该属性在props中没有声明接收的所有的对象），emit方法（分发事件的），slots对象（插槽）
    console.log(props);//从props中获取自己传递的状态
    console.log(context);//可以从emit方法中获取子级传递的方法。
    const store = useStore()
    const activeIndex = "1"


    return {
      activeIndex,
      store
    }
  },
  components: {
    HelloWorld
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key);
      switch (key){
        case "1":
          this.$router.push('/')
          break;
        case "2":
          this.$router.push('/login')
          break;
        case "3-1":
          this.$router.push('/login')
          break;
        case "3-2":
          this.$router.push('/modifyPwd')
          break;
      }


    }
  }
}
</script>
<style scoped lang="scss">
.app-container {
  width: 100%;
  margin-bottom: 30px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}
</style>
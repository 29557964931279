import { createStore } from 'vuex'
import user from './modules/user'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  // 区分模块，引入模块即可
  modules: {
    user
  }
})
